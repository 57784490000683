

/* General container styling */
.container {
    margin-top: 50px;
  }
  
  /* Trending title styling */
  .trending {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  

  /* Card styling */
  .product-card-container {
    padding: 0 15px;
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  /* Image styling */
  .image {
    height: 250px;
    object-fit: cover;
  }
  
  /* Product info section */
  .product-info {
    padding: 15px;
  }
  
  /* Button styling */
  .button {
    display: block;
    width: 100%;
    margin-top: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #218838;
  }
  
.price{
  color: #6E8A3D !important ;
}