/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #2a522d;
}

.checkout-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #c8eec1;
    border-radius: 8px;
    box-shadow: 0 4px 10px #2a522d;
}

.checkout-container h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #2a522d;
    
}

.cart-items {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}

.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;
    border-top: 1px solid #eee;
}

.cart-item img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    object-fit: cover;
}

.cart-item div {
    font-size: 1rem;
    
}

.shipping-details {
    margin-top: 1.5rem;
}

.shipping-details h3 {
    margin-bottom: 1rem;
  
}

.shipping-details input {
    width: 100%;
    padding: 0.8rem;
    margin: 0.5rem 0;
    font-size: 1rem;
    border: 1px solid #2a522d;
    border-radius: 4px;
    transition: border-color 0.3s;
}

.shipping-details input:focus {
    border-color: #2a522d;
    outline: none;
}

.total-price {
    text-align: right;
    font-size: 1.2rem;
    font-weight: bold;
  
    margin-top: 1.5rem;
}

.checkout-button {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
    color: #c8eec1;
    background-color: #2a522d;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1.5rem;
    transition: background-color 0.3s;
}

.checkout-button:hover {
    background-color: #2a522d;
}
