@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

*{
   margin: 0;
   padding: 0;
}
body {
   font-family: Arial, sans-serif;
   background-color: #f8f9fa;   
}
/* Sidebar Styles */
.sidebar {
   background-color: #c8eec1;
 width: 300px;
   display: flex;
   flex-wrap: wrap;
   margin-left: 40px;
   /* padding-top: 15px; */
}

.sidebar .nav-link {
   color: #2A522D;
   padding: 10px;
   display: flex;
}

/* Card Styles */
.card {
   margin-top: 20px;
}

.card-title {
   font-size: 1.5rem;
}



/* .table th,
.table td {
   vertical-align: middle;
}

.table th {
   background-color: #f2f2f2;
} */