

/* Profile container centered and responsive */
.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150vh;
    background-color: #f0f2f5;
    padding: 20px;
    box-sizing: border-box;
}

/* Profile card styling */
.profile-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    padding: 30px;
    background-color: #2A522D;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: white;
    margin-bottom: 30px;
}
.profile-card p{
    color: white;
}

/* Profile heading and text */
h1 {
    color: white;
    margin-bottom: 15px;
}

.profile-info {
    font-size: 1.1rem;
    margin: 5px 0;
    color: white;
}

/* Orders container layout */
.orders-container {
    width: 100%;
    max-width: 800px;
    margin-top: 30px;
}

/* Order list styles */
.order-list {
    list-style: none;
    padding: 0;
}

.order-item {
    border: 1px solid #2A522D;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #c8eec1;
    transition: all 0.3s ease;
}

.order-item:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Order heading styles */
.order-item h3 {
    margin: 0;
    font-size: 1.3rem;
    color: #2A522D;
}

/* Order details: date, price, and status */
.order-item p {
    font-size: 1rem;
    color: #2A522D;
    margin: 5px 0;
}

/* Order items (products) styling */
.order-item ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.order-item ul li {
    font-size: 0.95rem;
    margin-bottom: 10px;
    padding-left: 10px;
    color: #444;
}

.order-item ul li p {
    margin: 5px 0;
}

/* Styling for no orders message */
.no-orders {
    font-size: 1rem;
    color: #2A522D;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .profile-card {
        width: 90%;
        padding: 20px;
    }

    .orders-container {
        width: 100%;
    }

    .order-item {
        padding: 15px;
    }

    .profile-info {
        font-size: 1rem;
    }

    .order-item h3 {
        font-size: 1.1rem;
    }

    .order-item p {
        font-size: 0.9rem;
    }
}
