*{
    margin: 0;
    padding: 0;
}
h1{
    color: #2A522D;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #c8eec1;
}

.orders-table th, .orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.orders-table th {
    background-color: #2A522D;
    color: white;
}

.orders-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.orders-table tr:hover {
    background-color: #c8eec1;
}

.orders-table td ul {
    list-style: none;
    padding: 0;
}

.orders-table td ul li {
    margin-bottom: 5px;
}
