

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
  h1 {
    color: #2d572c;
  
    margin-right: 40px;
  }

  .create-account-form {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px;
    border: 2px solid #2d572c;
    border-radius: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-size: 20px !important ;
    margin-bottom: 5px;
    color: #2d572c;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #2d572c;
    border-radius: 3px;
    font-size: 12px;

  }
  
  .submit-button {
    margin-top: 30px;
    padding: 10px;
    background-color: #2d572c;
    color: white;
    border: none;
    border-radius: 3px;

    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #006f4c;
  }
  
p{
  font-size: 20px;
}
span{
  color:#2d572c !important;
}