 
 @import "slick-carousel/slick/slick.css";
 @import "slick-carousel/slick/slick-theme.css";
 .trending{
 margin-top: 50px !important;
font-size: 30px ;
color: #2A522D;
}
.main-image{
    height:auto !important;
}
