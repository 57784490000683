.logo {
    width: 150px;
    height: auto;
    margin-left: 40px;
}

/* Make sure search bar adjusts on smaller screens */
.search-container {
    position: relative;
}

.search-input {
    padding: 8px 30px;
    font-size: 18px;
    width: 100%; /* Set width to 100% to allow flexibility on small screens */
    max-width: 300px; /* Limit max width for larger screens */
    border: none;
    border-bottom: 1px solid #2a522d;
}

.search-input:focus {
    outline: none;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #2a522d;
}

/* Profile icon and cart icon styles */
.profile-icon, .cart-icon {
    font-size: 25px;
    color: #2a522d !important;
    margin-left: 25px;
    cursor: pointer;
    margin-top: 10px;
}

/* Sign-in link style */
.sign-in {
    font-size: 20px;
    color: #1A311F;
    margin-left: 25px;
    cursor: pointer;
    margin-top: 10px;
}

/* Adjust layout for smaller screens */
@media (max-width: 768px) {
    .d-flex {
        flex-wrap: wrap; /* Allow wrapping of items */
        justify-content: space-between;
    }

    .logo {
        margin-left: 10px; /* Reduce margin for smaller screens */
    }

    .search-container {
        width: 100%; /* Full width search bar */
        margin-bottom: 10px; /* Add space below */
    }

    .search-input {
        width: 100%; /* Ensure it takes full width */
        max-width: none; /* Remove max width */
    }

    .d-flex.align-items-center {
        width: 100%; /* Allow items to stack */
        justify-content: space-between; /* Spread items evenly */
    }

    .profile-icon, .cart-icon, .sign-in {
        margin-left: 10px; /* Adjust margins */
        margin-top: 5px; /* Reduce space between icons */
    }
}

/* For very small screens, adjust further */
@media (max-width: 480px) {
    .logo {
        width: 120px; /* Make logo smaller */
    }

    .search-input {
        font-size: 16px; /* Reduce font size for smaller screens */
    }

    .profile-icon, .cart-icon, .sign-in {
        font-size: 20px; /* Reduce icon size */
        margin-left: 5px; /* Adjust spacing */
    }
}