.cart-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    color:#2A522D;
}

.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    flex-wrap: wrap;
}

.product-images {
    width: 150px;  /* Adjusted width */
    height: 150px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-images img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.item-details {
    flex: 1;
    padding-left: 20px;
}

.item-name {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}

.item-price {
    font-size: 14px;
}

.quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.quantity-controls button {
    padding: 5px 10px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.quantity-controls input {
    width: 50px;
    text-align: center;
    padding: 5px;
}

.remove-item {
    padding-left: 20px;
}

.remove-icon {
    font-size: 20px;
    color: #FF0000;
    cursor: pointer;
}

.summary {
    padding: 20px;
    margin-top: 30px;
}

.checkout-button {
    background-color: #2A522D;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.checkout-button:hover {
    background-color: #006f4c;
}
