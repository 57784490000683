.container {
    margin-top: 20px;
}

.trending {
    margin-bottom: 30px;
}

.sidebar {
    padding: 15px;
    background-color: #c8eec1;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.sidebar h4 {
    margin-bottom: 20px;
}

.sidebar .btn {
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.product-card {

    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px;
    background-color: #fff;
    transition: transform 0.3s ease;
}

.product-card:hover {
    transform: translateY(-5px);
}

.product-image img {
    max-height: 250px;
    object-fit: cover;
}

.product-info {
    padding: 15px 10px;
}

.price {
    color: red;
    font-weight: bold;
}

.pagination {
    margin-top: 20px;
}

.pagination .page-item .page-link {
    background-color: transparent;
    border: 1px solid #ddd;
}

a{
    color: #2A522D ;
  font-size: 20px;
}