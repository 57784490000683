*{
    margin: 0;
    padding: 0;
}

.product{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
}
.product h1{
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: 2px;
    letter-spacing: 2px;
}
.product form{
    min-width: 500px;
    min-height: 350px;
    /* background-color: #c8eec1; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    box-shadow: 1px 1px 1px 1px #c8eec1;
}
.product form input{
    width: 90%;
    background-color: #c8eec1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: none;
    border-radius: 8px;
}
.product form input::placeholder{
    color: #2A522D;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 18px;
}

.product form button{
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #2A522D;
    background-color: #c8eec1;
    border-radius: 8px;
    transition: 1s;
    font-size: 18px;
    cursor: pointer;
}
.product form button:hover{
    transition: 1s;
    background-color:#2A522D;
    color: #c8eec1;
}

.success-popup {
    background-color: #2A522D;
    color: #c8eec1;
    padding: 10px;
    /* position: fixed; */
    top: 10px;
    right: 10px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 4px 8px #2A522D;
    font-size: 16px;
}
