.dropdown-toggle::after {
  display: none;
}

.navbar-nav .nav-item.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1000;
}

.nav-item .dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-item.dropdown:hover > .dropdown-menu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
}

.dropdown-item {
  color: #2A522D !important;
  font-size: 14px !important;
}

.dropdown-item a {
  text-decoration: none;
  color: #2A522D;
  font-size: 14px;
}

.nav-link.dropdown-toggle {
  color: white;
  margin-left: 15px;
  transition: transform 0.2s ease-in-out;
  font-size: 20px;
}

.nav-link.dropdown-toggle:hover {
  transform: scale(1.1);
}

.navbar {
  background-color: #2A522D !important;
  z-index: 10000;
}

/* Adjust nested dropdown position */
.dropdown-item.dropdown {
  position: relative;
  width: 50%;
}