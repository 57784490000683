.name{
 
}
.quantity-selector{
margin-top: 50px !important;

}
.price{
    margin-top: 20px;
    color: #56AD42 !important;
    font-weight: lighter;
}
.buttons{
    padding: 2px 10px;

}
.add{
  padding: 10px 100px;
}

.other-products{
  margin-top: 50px;
  padding: 30px;
color: #2A522D;
}